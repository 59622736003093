<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{ name: page_route }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <BtnCircle
            v-if="$store.getters.getLogin.permissions.insureds.read"
            tLabel="Asegurados"
            tPosition="bottom"
            icon="mdi-clipboard-account"
            color="warning"
            size="x-small"
            :to="{
              name: 'asegurados',
              params: { contractor_id: id },
            }"
          />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                color="pink"
                :to="{
                  name: 'contratantes.cpts',
                  params: { id: id },
                }"
              >
                <v-icon> mdi-clipboard-list </v-icon>
              </v-btn>
            </template>
            <span v-text="'CPTS'" />
          </v-tooltip>
          <BtnCircle
            v-if="$store.getters.getLogin.permissions.contractors.update"
            tLabel="Editar"
            tPosition="bottom"
            icon="mdi-pencil"
            color="info"
            size="x-small"
            :to="{ name: `${page_route}.update`, params: { id: id } }"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'GENERAL'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Nombre" :value="data.name" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Alias" :value="data.alias" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Red de Proveedores"
                    :value="data.provider_group.provider_group"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Red de Médicos"
                    :value="data.doctor_group.doctor_group"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Concentrador"
                    :value="
                      data.contractor_brand_id
                        ? data.contractor_brand.contractor_brand
                        : 'NO APLICA'
                    "
                    :alert="!data.contractor_brand_id ? true : false"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Formato migración"
                    :value="
                      data.contractor_insured_format
                        ? data.contractor_insured_format.name
                        : '-'
                    "
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'FISCAL'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Razón social" :value="data.legal_name" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="RFC" :value="data.code_tax" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Código postal" :value="data.legal_zip" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Régimen"
                    :value="
                      data.fiscal_regime_id
                        ? data.fiscal_regime.fiscal_regime
                        : null
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Uso de CFDI"
                    :value="
                      data.fiscal_use_id ? data.fiscal_use.fiscal_use : null
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Método de pago"
                    :value="
                      data.fiscal_method_payment_id
                        ? data.fiscal_method_payment.fiscal_method_payment
                        : null
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Forma de pago"
                    :value="data.fiscal_is_ppd ? 'PPD' : 'PUE'"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'CONFIGURACIÓN'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Polizario"
                    :value="data.insured_list ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Credencial"
                    :value="data.credential ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Carta exprés"
                    :value="data.express_letter ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Consultas"
                    :value="data.consultation ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Telemedicina"
                    :value="data.telemedicine ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Seguimiento médico"
                    :value="data.medical_monitoring ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Consolidado"
                    :value="data.consolidated ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Verificación 2 pasos"
                    :value="data.mfa ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="INFO visualizar servicios"
                    :value="data.external ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col v-if="data.external" cols="12" xs="12" md="3">
                  <ViewData
                    label="Cantidad de servicios"
                    :value="data.external_items"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="INFO verificar código"
                    :value="data.verify_code ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Reembolso"
                    :value="data.refund ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Programación de cirugía"
                    :value="data.surgery_schedule ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Urgencias"
                    :value="data.urgency ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Aperturas"
                    :value="data.opening ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Alta sin carta definitiva"
                    :value="data.dicharged_without_last_letter ? 'SI' : 'NO'"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'CONDICIONES INGRESOS'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Factor CPT" :value="data.cpt_factor" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Tope máx. suma asegurada"
                    :value="data.max_assured"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Monto carta autorización exprés"
                    :value="data.amount_express"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Tipo de deducible"
                    :value="
                      data.type_deductible == 1
                        ? 'Deducible'
                        : data.type_deductible == 2
                        ? 'Por padecimiento inicial'
                        : 'Por enfermedad'
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Monto de deducible"
                    :value="data.amount_deductible"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Tipo de coaseguro"
                    :value="
                      data.type_coinsurance == 1
                        ? 'Coaseguro'
                        : 'Por enfermedad'
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Porcentaje de coaseguro % "
                    :value="data.percentage_coinsurance"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Coaseguro HM %"
                    :value="data.fee_coinsurance"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Tope de Deducible y Coaseguro"
                    :value="data.max_coinsurance"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Revisiones de cartas"
                    :value="data.letters_review"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Deducibles anuales"
                    :value="data.annual_deductible ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <ViewData label="Leyendas" :value="data.caption" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2
                class="text-caption"
                v-text="'CONDICIONES SERVICIOS CONSULTAS'"
              />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Monto de deducible"
                    :value="data.rs_deducible_amount"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Coaseguro %" :value="data.rs_coinsurance" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Tope de Deducible y Coaseguro"
                    :value="data.rs_coinsurance_amount"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="12">
                  <ViewData label="Leyendas" :value="data.rs_caption" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2
                class="text-caption"
                v-text="'CONDICIONES SERVICIOS PROVEEDORES'"
              />
            </v-card-title>
            <v-divider />
            <v-card-text v-for="(letter, i) in data.letters" :key="i">
              <v-row>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    :label="`Tipo de proveedor (${i + 1})`"
                    :value="letter.provider_type.provider_type"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="2">
                  <ViewData
                    label="Monto de deducible"
                    :value="letter.deducible_amount"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="2">
                  <ViewData label="Coaseguro %" :value="letter.coinsurance" />
                </v-col>
                <v-col cols="12" xs="12" md="2">
                  <ViewData
                    label="Tope de Deducible y Coaseguro"
                    :value="letter.coinsurance_amount"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="2">
                  <ViewData
                    label="Tope máximo"
                    :value="letter.assured_amount"
                  />
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  md="2"
                  v-if="letter.provider_type_id === 1"
                >
                  <ViewData
                    label="Días de expiración"
                    :value="letter.expiration_days"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  :md="letter.provider_type_id === 1 ? 10 : 12"
                >
                  <ViewData label="Leyendas" :value="letter.caption" />
                </v-col>
                <v-col cols="12">
                  <hr />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'PORCENTAJES POR ENTIDADES'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  v-for="(contractor_entity, i) in data.contractor_entities"
                  :key="i"
                >
                  <v-row dense>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        :label="`Entidad (${i + 1})`"
                        :value="contractor_entity.entity.entity"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        label="Porcentaje"
                        :value="contractor_entity.percent"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" v-if="data.user.active">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'USUARIO SISTEMA'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Correo electrónico"
                    :value="data.user.email"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'REGISTRO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Creación"
                    :value="!loading && data.created_by.email"
                    :subvalue="data.created_at"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="U. actualización"
                    :value="!loading && data.updated_by.email"
                    :subvalue="data.updated_at"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <BtnCircle
            v-if="
              $store.getters.getLogin.permissions.contractors.delete &&
              data.active
            "
            tLabel="Desactivar"
            tPosition="top"
            icon="mdi-delete"
            color="error"
            size="x-small"
            :click="HandleDesactivate"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken, msgConfirm, msgAlert } from "../../control";
import { show, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      page_route: "contratantes",
      id: this.$attrs.id,
      login: this.$store.getters.getLogin,
      loading: true,
      data: { provider_group: {}, doctor_group: {} },
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    HandleDesactivate() {
      this.$swal
        .fire(msgConfirm("Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy(
              "contractors",
              this.login.token,
              this.id,
              this.login.id
            ).then((response) => {
              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              response.success
                ? this.$router.push({ name: this.page_route })
                : console.log(response.message);
            });
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    show("contractors", this.login.token, this.id).then((response) => {
      this.data = response;
      this.loading = false;
    });
  },
};
</script>